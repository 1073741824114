import React, { useEffect, useLayoutEffect, useState } from 'react';

export function XTweet(tweetId: string, maxHeight: string) {
  return (
    <div style={{
      position: "absolute", 
      bottom: 0,
      right: 0,
      maxHeight: maxHeight,
      overflowY: "scroll", 
      zIndex: 4, 
      borderTopLeftRadius: "1vw",
      borderTopRightRadius: "1vw"
    }}>
      <blockquote className="twitter-tweet" data-theme="dark" data-width={250} data-margin-bottom={0} data-chrome="nofooter noborders transparent"><p lang="ja" dir="ltr"></p>&mdash; 合同会社らっくらく (@rakkuraku_x) <a href={`https://twitter.com/rakkuraku_x/status/${tweetId}?ref_src=twsrc%5Etfw`}>rakkuraku tweet</a></blockquote>
      {/* <a
        className="twitter-timeline"
        href="https://twitter.com/rakkuraku_x"
        data-lang="ja"
        data-width={250}
        data-chrome="nofooter noborders transparent"
        data-tweet-limit="3"
        style={{backgroundColor: "#ffff3377",
          borderTopLeftRadius: "10px"
        }}
      >Tweets by @rakkuraku_x</a> */}
    </div>
  );
}