import React, { useEffect, useLayoutEffect, useState } from 'react';
// import LogoBack from "./assets/logo/back.svg";
import './App.css';
import Westasabu from './components/effects/logo/westasabu'
import Rotate3d from './components/effects/logo/rotate3d'
import TextSplash from './components/effects/text/splash'
import ClockA from './components/effects/clock/a'
import { UtilRand } from './utils/rand';
import { XTweet } from './components/x/tweet';
import { XRandom } from './components/x/random';

type SplashText = {
  text: string
  numMax: number
}

type SplashSetting = {
  fontFamilies: string[]
  messages: SplashText[]
  anniversary?: {[name:string]: SplashText[]}
}

const fontFamilies = [
  "MoiraiOne-Regular",
  // "RubikPuddles-Regular",
  // "FasterOne-Regular"
]

const fontFamiliesJa = [
  "Toroman"
]

const splashs: {[name:string]: SplashSetting} = {
  en: {
    fontFamilies: [
      "MoiraiOne-Regular",
      // "Toroman"
    ],
    messages: [
      {text: "synergy", numMax: 3},
      {text: "pop", numMax: 3},
      {text: "!", numMax: 2},
      {text: "?", numMax: 3},
      {text: "sustainable", numMax: 2},
      {text: "try and error", numMax: 5},
    ],
    anniversary: {
      "1/1": [
        {text: "HAPPY", numMax: 3},
        {text: "NEW YEAR!", numMax: 3},
        {text: new Date().getFullYear().toString(), numMax: 3},
      ]
    }
  },
  ja: {
    fontFamilies: [
      // "MoiraiOne-Regular",
      "Toroman"
    ],
    messages: [
      {text: "アジアゴールデンキャット", numMax: 3},
      {text: "アムールトラ", numMax: 3},
      {text: "アムールヒョウ", numMax: 3},
      {text: "アムールヤマネコ", numMax: 3},
      {text: "インドライオン", numMax: 3},
      {text: "オオヤマネコ", numMax: 3},
      {text: "カラカル", numMax: 3},
      {text: "キングチーター", numMax: 3},
      {text: "サーバルキャット", numMax: 3},
      {text: "スナドリネコ", numMax: 3},
      {text: "スナネコ", numMax: 3},
      {text: "スマトラトラ", numMax: 3},
      {text: "キングチーター", numMax: 3},
      {text: "チョウセントラ", numMax: 3},
      {text: "ツシマヤマネコ", numMax: 3},
      {text: "ヒョウ", numMax: 3},
      {text: "とら", numMax: 3},
      {text: "ベンガルトラ", numMax: 3},
      {text: "ベンガルヤマネコ", numMax: 3},
      {text: "ボブキャット", numMax: 3},
      {text: "ホワイトタイガー", numMax: 3},
      {text: "マヌルネコ", numMax: 3},
      {text: "ヨーロッパオオヤマネコc", numMax: 3},
      {text: "ライオン", numMax: 3},
      // {text: "いいね！", numMax: 3},
      {text: "㍉", numMax: 2},
      // {text: "☆", numMax: 3},
      {text: "クロヒョウ", numMax: 2},
      {text: "クロジャガー", numMax: 5},
      {text: "ユキヒョウ", numMax: 5},
      {text: "ジャガー", numMax: 5},
      {text: "ウンピョウ", numMax: 5},
      {text: "ピューマ", numMax: 5},
      {text: "チーター", numMax: 5},
      {text: "オセロット", numMax: 5},
      {text: "みけねこ", numMax: 5},
    ],
    anniversary: {
      "12/24": [
        {text: "メリー", numMax: 3},
        {text: "クリスマス", numMax: 3},
        {text: "オーナメント", numMax: 3},
        {text: "リース", numMax: 3},
        {text: "ジングル", numMax: 3},
        {text: "ベル", numMax: 3},
        {text: "キャンドル", numMax: 3},
        {text: "キャロル", numMax: 3},
        {text: "クッキー", numMax: 3},
        {text: "スノーマン", numMax: 3},
        {text: "グリーティング", numMax: 3},
        {text: "カード", numMax: 3},
        {text: "🦌", numMax: 3},
        {text: "🎅", numMax: 3},
        {text: "🎁", numMax: 3},
        {text: "しちめんちょう🦃", numMax: 3},
        {text: "チョコレート", numMax: 3},
        {text: "ブッシュド", numMax: 3},
        {text: "ノエル", numMax: 3},
        {text: "🎂", numMax: 3},
        {text: "⛄️", numMax: 3},
        {text: "🌲", numMax: 3},
        {text: "ガーリック", numMax: 3},
        {text: "シュリンプ🦐", numMax: 3},
        {text: "フライド", numMax: 3},
        {text: "チキン🍗", numMax: 3},
        {text: "きよし", numMax: 3},
        {text: "このよる🌃", numMax: 3},
        {text: "ホワイト", numMax: 3},
      ],
      "12/25": [
        {text: "メリー", numMax: 3},
        {text: "クリスマス", numMax: 3},
        {text: "🦌", numMax: 3},
        {text: "🎅", numMax: 3},
        {text: "🎁", numMax: 3},
        {text: "しちめんちょう🦃", numMax: 3},
        {text: "チョコレート", numMax: 3},
        {text: "ブッシュド", numMax: 3},
        {text: "ノエル", numMax: 3},
        {text: "🎂", numMax: 3},
        {text: "⛄️", numMax: 3},
        {text: "🌲", numMax: 3},
        {text: "ガーリック", numMax: 3},
        {text: "シュリンプ🦐", numMax: 3},
        {text: "フライド", numMax: 3},
        {text: "チキン🍗", numMax: 3},
        {text: "きよし", numMax: 3},
        {text: "このよる🌃", numMax: 3},
        {text: "ホワイト", numMax: 3},
      ],
      "1/1": [
        {text: "あけまして", numMax: 3},
        {text: "おめでとうございます", numMax: 3},
        {text: "A", numMax: 3},
        {text: "HAPPY", numMax: 3},
        {text: "NEW YEAR!", numMax: 3},
        {text: "🎍", numMax: 3},
        {text: "🍊", numMax: 3},
        {text: new Date().getFullYear().toString(), numMax: 3},
      ]
    }
  }
}

const langs = [
  "en",
  "ja"
]
const lang = langs[Math.floor(Math.random() * (langs.length))]
const splash = splashs[lang]
const fontFamily = splash.fontFamilies[Math.floor(Math.random() * (splash.fontFamilies.length))]
let splashDefines = splash.messages
const today = `${new Date().getMonth() + 1}/${new Date().getDate()}`
if (splash.anniversary && splash.anniversary[today]) {
  splashDefines = splash.anniversary[today]
}

const sai = UtilRand.num(1)

function App() {
  const width = window.innerWidth - 100
  const ratio = 400 / 114
  const [w, setW] = useState(width);
  const [h, setH] = useState(width / ratio);
  const intervalSec = UtilRand.num(9) + 1;

  useLayoutEffect(() => {
    const updateSize = (): void => {
      const width = window.innerWidth - 100
      setW(width)
      setH(width / ratio)
    };
    window.addEventListener('resize', updateSize);
    window.addEventListener('orientationchange', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  let xPanelMaxHeight = "38vh"

  if (
    window.screen.orientation.type === "portrait-primary" ||
    window.screen.orientation.type === "portrait-secondary"
  ) {
    xPanelMaxHeight = "33vh"
  }

  // const splashDefines: SplashText[] = [
  //   {text: "synergy", numMax: 3},
  //   {text: "pop", numMax: 3},
  //   // {text: "rock", numMax: 5},
  //   // {text: "☆", numMax: 1},
  //   {text: "!", numMax: 2},
  //   {text: "?", numMax: 3},
  //   {text: "sustainable", numMax: 2},
  //   {text: "try and error", numMax: 5},
  //   // {text: "don't repeat your self", numMax: 1},
  //   // {text: "eureka", numMax: 1},
  // ]

  const splashTextCount = 30
  const splashTexts: SplashText[] = []

  for (let i = 0; i < splashTextCount; i++) {
    splashTexts.push(UtilRand.array(splashDefines))
  }

  return (
    <div className="App" style={{position: "relative", overflow: "hidden"}}>
      {XRandom(xPanelMaxHeight)}
      {/* {XTweet("1868153235636441534", xPanelMaxHeight)} */}
      {/* <div style={{
        position: "absolute", 
        bottom: 0,
        right: 0,
        maxHeight: xPanelMaxHeight,
        overflowY: "scroll", 
        zIndex: 4, 
        borderTopLeftRadius: "1vw",
        borderTopRightRadius: "1vw"
      }}>
        <blockquote className="twitter-tweet" data-theme="dark" data-width={250} data-margin-bottom={0} data-chrome="nofooter noborders transparent"><p lang="ja" dir="ltr">4日経っても届かない😇XのDeveloper Portalを確認すると月間使用量が100%超えてる〜📷原因がわかるまでホームページのタイムライン埋込は停止します📷😭</p>&mdash; 合同会社らっくらく (@rakkuraku_x) <a href="https://twitter.com/rakkuraku_x/status/1868153235636441534?ref_src=twsrc%5Etfw">December 15, 2024</a></blockquote>
        {/* <a
          className="twitter-timeline"
          href="https://twitter.com/rakkuraku_x"
          data-lang="ja"
          data-width={250}
          data-chrome="nofooter noborders transparent"
          data-tweet-limit="3"
          style={{backgroundColor: "#ffff3377",
            borderTopLeftRadius: "10px"
          }}
        >Tweets by @rakkuraku_x</a> */}
      {/* </div> */}
      <header className="App-header" style={{position: "relative",}}>
        <div style={{position: "relative", opacity: 0.9}}>
          {
            splashTexts.map((text, i) => {
              return TextSplash(w, h, UtilRand.num(9) + 10, fontFamily, text.text, text.numMax, `_splash_${i}`)
            })
          }
          {
            Rotate3d(w, h, intervalSec)
          }
        </div>
        {ClockA(w, h, fontFamily)}
      </header>
    </div>
  );
}

export default App;
