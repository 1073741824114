import React, { useEffect, useLayoutEffect, useState } from 'react';
import { UtilRand } from '../../../utils/rand';
import { Animix, UtilAnimation } from '../../../utils/animations/index';
import { AnimationTimingFunction } from '../../../utils/animations/props';

function App(w: number, h: number, intervalSec: number, fontFamily: string, text = "synergy", elementsNumMax = 10, suffix = "") {
  const [ styleAnima, setStyleAnima] = useState("")
  const [ elements, setElements] = useState<JSX.Element[]>([])
  
  function draw() {
    const elementsNum = UtilRand.num(elementsNumMax) + 1
    let style: string = ""
    const elementsSize = UtilRand.num(300)
    const elementScaleRatio = 100
    const elms: JSX.Element[] = []
    // const top = (h / 2) - (elementsSize / ratio / 2)
    // const left = (w / 2) - elementsSize * text.length / 2
    // console.log(top, left)
    const styleProps: React.CSSProperties = {
      color: UtilRand.color(250, true),
      position: "absolute",
      fontSize: elementsSize,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      fontFamily,
      backfaceVisibility: "hidden",
      WebkitBackfaceVisibility: "hidden",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
    }

    const i = 0
    const className = `custom_animix_star_${suffix}${i}`
    const animix = new Animix()
    let x = UtilRand.num(100)
    if (UtilRand.num(1) === 1) x *= -1
    let y = UtilRand.num(100)
    if (UtilRand.num(1) === 1) y *= -1
    let deg = UtilRand.num(710)
    if (UtilRand.num(1) === 1) deg *= -1
    // console.log(x, y, deg)
    animix.addStyle(UtilAnimation.rotate3d.gyun2(i, 10, elementScaleRatio, x, y, suffix, deg), intervalSec, AnimationTimingFunction.linear, true)
    style += animix.getStyle(className)
    elms.push(<div className={className} style={styleProps}>{text}</div>)
  
    setStyleAnima(style)
    setElements(elms)

    setTimeout(draw, intervalSec * 1000)
  }

  useEffect(() => {
    draw()
  }, []);
  
  return (
    <div style={{
      width: w, 
      height: h,
      position: "absolute",
      top: 0,
      left: 0
    }}>
      <style>
        {styleAnima}
      </style>
      {elements}
    </div>
  );
}

export default App;
