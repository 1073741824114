import React, { useEffect, useLayoutEffect, useState } from 'react';
import { XTweet } from './tweet';

export function XRandom(maxHeight: string) {
  useEffect(() => {
    // fetch('https://rakkuraku.net', {
    //   method: 'GET', 
    //   // headers: {
    //   //   'Content-Type': 'application/json'
    //   // },
    //   // body: JSON.stringify({
    //   //   userId: "1831578876524556289",
    //   //   limit: 1,
    //   //   token: "234567890ooiuhgfcvoeiewq"
    //   // })
    // })
    // .then(res => {
    //   console.log(res)
    // })
    // .then(data => {
      
    // })
  }, []);

  return XTweet("1868153235636441534", maxHeight)
}
